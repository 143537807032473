import React, {Fragment, useContext, useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTIcon,} from '../../../_metronic/helpers'
import { useNavigate } from 'react-router'

import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'
import ReactPaginate from 'react-paginate'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import moment from 'moment'
import { Button, Col, Input, Label, Row, Table } from 'reactstrap'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import { JobLegsService } from '../../modules/Services/JobLegs'
import SmsModel from './SmsModel'
import Flatpickr from 'react-flatpickr'
import ReactSelect from 'react-select'
import { selectThemeColors } from '../../modules/commonModel/Utils'
import { DriverService } from '../../modules/Services/Drivers'
import { CustomerService } from '../../modules/Services/Customers'
import AsyncSelect from 'react-select/async'
import { Controller } from 'react-hook-form'
import UILoader from '../../modules/commonModel/Ui-loader'
import { Icon } from '@iconify/react'
import { FilterDetails } from '../../App'
import { TagsService } from '../../modules/Services/Tags'
import Popover from '@mui/material/Popover';
// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file

import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { IconComponent } from '../../../_metronic/helpers/components/IconComponent'
import ChangeJobStatus from './ChangeJobStatus'

const JobLegsPage = () => {
  const tableHearder = [
    {label: 'Job ID', asc_desc: undefined, value: 'job_number', style: 'pointer'},
    {label: 'Pickup Date', asc_desc: undefined, value: 'start_at_date', style: 'pointer'},
    {label: 'Client', asc_desc: undefined, value: null, style: ''},
    {label: 'Details', asc_desc: undefined, value: null, style: ''},
    {label: 'Tags', asc_desc: undefined, value: null, style: ''},
    {label: 'PAX/Vehicles', asc_desc: undefined, value: null, style: ''},
    {label: 'Vehicle', asc_desc: undefined, value: null, style: ''},
    {label: 'Driver', asc_desc: undefined, value: null, style: ''}
  ]

  const [pending, setPending] = useState(false)
  const [filterValue, setFilterValue] = useContext(FilterDetails)
  const navigate = useNavigate()
  const [jobLegsData, setJobLegsData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const entity_id = localStorage.getItem('entity_id')
  const [date, setDate] = useState(null)
  const [currentCustomer, setCurrentCustomer] = useState((Object.keys(filterValue?.jobs?.customer).length > 0) ? filterValue?.jobs?.customer : { value: '', label: 'Select Client' })
  const [searchTerm, setSearchTerm] = useState(Object.keys(filterValue?.jobs?.job_search)?.length ? filterValue?.jobs?.job_search : '')
  const [statusData, setStatusData] = useState([])
  const [currentStatus, setCurrentStatus] = useState((Object.keys(filterValue?.jobs?.status).length > 0) ? filterValue?.jobs?.status : { value: '', label: 'Select Status' })
  const [currentDriver, setCurrentDriver] = useState((Object.keys(filterValue?.jobs?.driver).length > 0) ? filterValue?.jobs?.driver : { value: '', label: 'Select Driver' })
  const [renderElementService, setRenderElementService] = useState(1)
  const [nextPage, setNextPage] = useState(null)
  const [openSmsModel, setOpenSmsModel] = useState(false)
  const [tableHearderField, setTableHearderField] = useState(tableHearder)
  const [sortOrder, setSortOrder] = useState('desc');
  const [sort, setSort] = useState('start_at_date');
  const [tagData, setTagData] = useState([{ value: '', label: 'Select Tag'}])
  const [currentTag, setCurrentTag] = useState((Object.keys(filterValue?.jobs?.tag).length > 0) ? filterValue?.jobs?.tag : { value: '', label: 'Select Tag' })
  const [changeCheckbox, setChangeCheckbox] = useState([])
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange<Dayjs>>((Object.keys(filterValue?.jobs?.date).length > 0) ? filterValue?.jobs?.date : [null, null]);
  const [jobDate, setJobDate] = useState((Object.keys(filterValue?.jobs?.date).length > 0) ? filterValue?.jobs?.date : [null, null]);
  const [currentSelected, setCurrentSelected] = useState({ value: null, label: 'With Selected' })
  const [openJobStatus, setOpenJobStatus] = useState(false)
  
  const withSelectedData = [
    { value: null, label: 'With Selected' },
    { value: 'change_status', label: 'Change Status' }
  ]

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: 'Today',
      getValue: () => {
        const today = dayjs();
        return [today, today];
      },
    },
    {
      label: 'Yesterday',
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day');
        return [yesterday, yesterday];
      },
    },
    {
      label: 'Last 7 days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Last 30 days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(30, 'day'), today];
      },
    },
    {
      label: 'Last 90 days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(90, 'day'), today];
      },
    },
    {
      label: 'Last 365 days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(365, 'day'), today];
      },
    },
    {
      label: 'Last month',
      getValue: () => {
        const today = dayjs();
        const startOfLastMonth = today.subtract(1, 'month').startOf('month');
        const endOfLastMonth = today.subtract(1, 'month').endOf('month');
        return [startOfLastMonth, endOfLastMonth];
      },
    },
    {
      label: 'Last 12 months',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(12, 'month').startOf('day'), today];
      },
    },
    {
      label: 'Last year',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(1, 'year').startOf('day'), today];
      },
    },
    { label: 'Reset', getValue: () => [null, null] },
  ];

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let driverTimeoutId: ReturnType<typeof setTimeout> | null = null;
  let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;

  const loadOptions = (inputValue: any, callback: any) => {
    if (customerTimeoutId) {
      clearTimeout(customerTimeoutId);
    }
    customerTimeoutId = setTimeout(() => {
      const obj = { 
        getall: 1,
        fields: 'id,customer_name',
        customer_name : inputValue
      }
      CustomerService.getCustomerList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.customer_name}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000)
  }

  function getStatusList() {
    JobLegsService.jobLegStatus(entity_id)
    .then((res: any) => {
      const statusList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.label }
      })
      const defaultStatus = { value: null, label: 'Select Status' }
      setStatusData([defaultStatus, ...statusList])
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  const loadDriverOptions = (inputValue: any, callback: any) => {
    if (driverTimeoutId) {
      clearTimeout(driverTimeoutId);
    }
    driverTimeoutId = setTimeout(() => {
      const obj = { 
        getall: 1,
        fields: 'id,driver_name',
        driver_name : inputValue
      }
      DriverService.getDriverList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.driver_name}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000)
  }

  function getTagList() {
    const data = {
      getall: 1,
      fields: 'id,name'
    }
    TagsService.getTagsList(entity_id, data)
    .then((res: any) => {
      const tagsList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setTagData(pre => [...pre, ...tagsList])
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  useEffect(() => {
    getStatusList()
    getTagList()
  }, [])

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        scrollTop()
      }, 1000);
      return () => clearTimeout(delayDebounceFn)
    } else {
      scrollTop()
    }
  }, [searchTerm])

  useEffect(() => {
    if (renderElementService) {
      setRenderElementService(0)
      getJobLegs()
    }
  }, [renderElementService])

  const getJobLegs= () => {
    setPending(true)
    JobLegsService.getJobLegsList(entity_id, {
      per_page: 12,
      page: currentPage,
      customer: currentCustomer?.value ? currentCustomer?.value : null,
      status: currentStatus?.value ? currentStatus?.value : null,
      driver: currentDriver?.value ? currentDriver?.value : null, 
      // start_date : date ? date?.length > 1 ? moment(date[0]).format("YYYY-MM-DD") : null : null,
      // end_date : date ? date?.length > 1 ? moment(date[1]).format("YYYY-MM-DD") : null : null,
      start_date : jobDate?.length ? (jobDate[0] ? moment(jobDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
      end_date : jobDate?.length ? (jobDate[1] ? moment(jobDate[1]?.toISOString()).format("YYYY-MM-DD") : null ): null,
      sort_by: sortOrder ? sortOrder : null,
      order_by: sort ? sort : null,
      tag: currentTag?.value ? currentTag?.value : null,
      search: searchTerm ? searchTerm : null
    })
    .then((res: any) => { 
      if (currentPage > 1) setJobLegsData(pre => [...pre, ...res?.data?.response?.data]) 
      else setJobLegsData(res?.data?.response?.data)
      setNextPage(res?.data?.response?.next_page_url)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
    .finally(() => setPending(false))
  }

  const handleDateChange = (date: any) => {
    if (date?.length > 1) {
      scrollTop()
      setDate(date)
    } else if (date?.length === 0) {
      scrollTop()
      setDate(null)
    } else {
      setDate(null)
    }
  }

  function handleScroll(event: any) {
    const scrollHeight = event.target.scrollTop + event.target.clientHeight
    const elementHeight = event.target.scrollHeight
    if (event.target.scrollTop > 0) {
      if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
        setCurrentPage((p: any) => p + 1)
        setRenderElementService((prev) => prev + 1)
      }
    }
  }

  function scrollTop() {
    const table = document.getElementById('jobs_table')
    table.scrollTo(0, 0)
    setCurrentPage(1)
    setRenderElementService(1)
  }

  function onChangeFilter(event: any, fieldText: any) {
    setSortOrder('asc');
    const sort = tableHearderField?.map((item: any) => {
      if(item?.value == fieldText) {
        if(event) return {...item, asc_desc: false};
        return {...item, asc_desc: true};
      } else {
        return {...item, asc_desc: undefined}
      }
    })
    setTableHearderField(sort);
    if (event) setSortOrder('desc');
    setSort(fieldText);
    scrollTop();
  }

  function handleSearch(val: any) {
    setSearchTerm(val)
    setFilterValue({...filterValue, jobs: { ...filterValue?.jobs, job_search: val }})
  }

  function changeper(id: any, value: any) {
    let UpdateList = [...changeCheckbox]
    if (value) {
      UpdateList = [...changeCheckbox, id]
    } else {
      UpdateList.splice(changeCheckbox.indexOf(id), 1)
    }
    setChangeCheckbox(UpdateList)
  }

  function allCheckboxChange(value: any) {
    if (value) {
      const data = jobLegsData?.map((item: any) => item?.id)
      setChangeCheckbox(data)
    } else {
      setChangeCheckbox([])
    }
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateRangeChange = (newValue: DateRange<Dayjs>) => {
    setSelectedDateRange(newValue);
  };

  const handleClearDate = () => {
    scrollTop();
    setJobDate([null, null]);
    setSelectedDateRange([null, null]);
    setFilterValue({...filterValue, jobs: {...filterValue?.jobs, date: [null, null]}})
  };

  return (
    <>
      <SmsModel
        openSmsModel={openSmsModel}
        setOpenSmsModel={setOpenSmsModel}
        statusData={statusData}
        changeCheckbox={changeCheckbox}
        setRenderElementService={setRenderElementService}
      />
      <ChangeJobStatus
        openJobStatus={openJobStatus}
        setOpenJobStatus={setOpenJobStatus}
        statusData={statusData}
        changeCheckbox={changeCheckbox}
        setRenderElementService={setRenderElementService}
      />
      <KTCard className='mb-3'>
        <KTCardBody className='p-5'>
          <Row>
            <Col md= '2' className='d-flex align-items-center'>
                <Input type='search' placeholder='Search Jobs' value={searchTerm} onChange={(e) => handleSearch(e.target.value)}  />
            </Col>
            <Col md= '2' className='d-flex align-items-center'>
                <AsyncSelect
                  isClearable={true}
                  className='react-select w-100'
                  classNamePrefix='select'
                  placeholder='Select Client'
                  isRtl={false}
                  name='callback-react-select'
                  loadOptions={loadOptions}
                  value={currentCustomer}
                  cacheOptions
                  onChange={(data: any) => {
                    scrollTop()
                    setCurrentCustomer(data)
                    setFilterValue({...filterValue, jobs: {...filterValue?.jobs, customer: data || { value: '', label: 'Select Client' }}})
                  }}
                />
            </Col>
            <Col md= '2' className='d-flex align-items-center'>
              <ReactSelect
                isClearable={false}
                isSearchable={false}
                className='react-select w-100 text-start'
                classNamePrefix='select'
                theme={selectThemeColors}
                options={statusData}
                value={currentStatus}
                onChange={(data: any) => {
                  scrollTop()
                  setCurrentStatus(data)
                  setFilterValue({...filterValue, jobs: {...filterValue?.jobs, status: data}})
                }}
              />
            </Col>
            <Col md= '2' className='d-flex align-items-center'>
              <AsyncSelect
                isClearable={true}
                className='react-select w-100'
                classNamePrefix='select'
                placeholder='Search Driver'
                isRtl={false}
                name='callback-react-select'
                loadOptions={loadDriverOptions}
                value={currentDriver}
                cacheOptions
                onChange={(data: any) => {
                  scrollTop()
                  setCurrentDriver(data)
                  setFilterValue({...filterValue, jobs: {...filterValue?.jobs, driver: data  || { value: '', label: 'Select' }}})
                }}
              />
            </Col>
            <Col md= '2' className='d-flex align-items-center'>
              <ReactSelect
                isClearable={false}
                isSearchable={false}
                className='react-select w-100 text-start'
                classNamePrefix='select'
                theme={selectThemeColors}
                options={tagData}
                value={currentTag}
                onChange={(data: any) => {
                  scrollTop()
                  setCurrentTag(data)
                  setFilterValue({...filterValue, jobs: {...filterValue?.jobs, tag: data}})
                }}
              />
            </Col>
            <Col className='col-2 d-flex justify-content-end'>
              <div className="d-flex" style={{width: '300px'}}>
                <div style={{ position: 'relative', display: 'inline-block' }} className='w-100'>
                  {/* <Input onClick={handleClick} value={jobDate[0]?.startDate ? (moment(jobDate[0]?.startDate).format("DD-MM-YYYY") +
                    " To " + moment(jobDate[0]?.endDate).format("DD-MM-YYYY")) : ''} readOnly /> */}
                  <Input onClick={handleClick} value={jobDate[0] ? (moment(jobDate[0]?.toISOString()).format("DD-MM-YYYY") +
                    " To " + moment(jobDate[1]?.toISOString()).format("DD-MM-YYYY")) : ''}  placeholder='Search Date' readOnly />
                  {jobDate[0] && (
                    <IconComponent
                      iconName='cross'
                      className='fs-3'
                      iconType={undefined} 
                      onClick={handleClearDate}
                      style={{
                        border: '1px solid black',
                        borderRadius: '10px',
                        color: 'black',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDateRangePicker
                        value={selectedDateRange}
                        onChange={handleDateRangeChange}
                        slotProps={{
                          shortcuts: {
                            items: shortcutsItems,
                          },
                          actionBar: { actions: [] },
                        }}
                        calendars={2}
                      />
                    </LocalizationProvider>
                    <Row>
                      <Col className='mx-5 mb-5 d-flex justify-content-end'>
                        <Button
                          size="sm"
                          className='me-4'
                          color="primary"
                          onClick={() => {
                            setAnchorEl(null);
                            setJobDate(selectedDateRange)
                            setFilterValue({...filterValue, jobs: {...filterValue?.jobs, date: selectedDateRange}})
                            // setJobDate(state)
                            scrollTop()
                            // setCurrentPage(1)
                          }}
                        >
                          Apply
                        </Button>
                        <Button
                          size="sm"
                          color="secondary"
                          onClick={() => {
                            setAnchorEl(null);

                            // setState([{
                            //   startDate: null,
                            //   endDate: null,
                            //   key: 'selection'
                            // }])
                            // setJobDate([{
                            //   startDate: null,
                            //   endDate: null,
                            //   key: 'selection'
                            // }])
                            // scrollTop()
                            // setCurrentPage(1)
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Popover>
                </div>
              </div>
            </Col>
          </Row>
        </KTCardBody>
      </KTCard>
      <KTCard>
        <KTCardBody className='p-0 pt-1'>
          <div className='invoice-list-table-header w-100 px-10 my-5'>
            <Row>
              <Col md="2" className='d-flex align-items-center p-0'>
                <ReactSelect
                  isClearable={false}
                  isSearchable={false}
                  className='react-select w-100 text-start'
                  classNamePrefix='select'
                  theme={selectThemeColors}
                  isDisabled={changeCheckbox?.length === 0}
                  options={withSelectedData}
                  value={currentSelected}
                  onChange={(data: any) => {
                    setCurrentSelected(data)
                    if (data?.value === 'change_status') setOpenJobStatus(true)
                  }}
                />
              </Col>
              <Col md="10" className='d-flex align-items-center justify-content-end p-0'>
                <div className="d-flex flex-row align-items-center">
                  <a href='#' className='btn btn-sm btn-primary' onClick={() => setOpenSmsModel(true)}>
                    <KTIcon iconName='plus' className='fs-3' />
                    Notify Drivers
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <UILoader blocking={ pending } loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
            <div onScroll={(e) => handleScroll(e)} id='jobs_table' className='jobs-table-style'>
              <Table className='jobs-table'>
                <thead className='sticky-header'>
                  <tr style={{fontWeight: '500'}}>
                    <th className='ps-3'>
                      <Input type='checkbox' checked={changeCheckbox?.length === jobLegsData?.length} onChange={(e) => allCheckboxChange(e.target.checked)} />
                    </th>
                    {tableHearderField?.map((item: any, index: any) => {
                      return (
                        <th className='ps-3' key={index}>
                          <div onClick={() => { item?.value ? onChangeFilter(item?.asc_desc, item?.value) : '' }} style={{cursor: item?.style}}>
                            {item.label}
                            {item?.value && item.asc_desc !== undefined &&
                              <span>
                                {item.asc_desc ? <Icon icon="raphael:arrowdown" /> : <Icon icon="raphael:arrowup" />}                                                            
                              </span>
                            }
                          </div>
                        </th>
                      )
                    })}
                    <th className='ps-3'>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {jobLegsData?.map((item: any, i: any) => {
                    return (
                      <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                        <td className='ps-3'>
                          <Input type='checkbox' value={item?.id} checked={changeCheckbox.includes(item?.id)} onChange={(e) => changeper(item?.id, e.target.checked)} />
                        </td>
                        <td className='ps-3' style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <span onClick={() => navigate(`/jobs/${item?.id}/edit`)} className='fw-bold cursor-pointer' >
                            {item?.job_number}
                          </span>
                          <div>{item?.status?.label}</div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>{item?.start_at_date ? moment(item?.start_at_date).format('DD-MM-YYYY') : ''} { item?.start_at_time ? item?.start_at_time : '' } </div>
                          {/* <div>{item?.end_at_date ? moment(item?.end_at_date).format('DD-MM-YYYY') : ''} { item?.end_at_time ? item?.end_at_time : '' }</div> */}
                        </td>
                        <td style={{maxWidth: '150px', overflow: 'hidden'}}>
                          <div>{item?.customer_name}</div>
                          <div>{item?.company_name}</div>
                        </td>
                        <td style={{maxWidth: '150px', overflow: 'hidden'}}>
                          <div>
                            {item?.pick_up_location ? (<><b className='me-2'><Icon icon="bi:pin-map-fill" style={{color: '#f68716'}} /> </b>{item.pick_up_location}</>) : null}
                          </div>
                          <div>
                            {item?.drop_off_location ? (<><b className='me-2'><Icon icon="bi:arrow-right-circle-fill" style={{color: '#f68716'}} /> </b>{item.drop_off_location}</>) : null}
                          </div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div className='d-flex'>
                            {item?.tags?.map((item: any, i: any) => {
                              return (
                                <span className='px-2 me-2' key={i} style={{color: 'white', backgroundColor: item?.color, borderRadius: '5px'}}>{item?.name}</span>
                              )
                            })}
                          </div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>{(item?.pax ? item?.pax : 'N/A') + ' / ' + item?.vehicles_required}</div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          {item?.driver_vehicles_arr?.map((data: any, i: any) => {
                            if (data?.vehicle?.vehicle_name) {
                              return <div key={i}>{data?.vehicle?.vehicle_name}</div>
                            }
                          })}
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          {item?.driver_vehicles_arr?.map((data: any, i: any) => {
                            if (data?.driver?.driver_name) {
                              return <div key={i}>{data?.driver?.driver_name}</div>
                            }
                          })}
                        </td>
                        <td className='ps-3'>
                          {item?.is_notified ?
                            <Icon icon="fa:bell" width="1.5rem" height="1.5rem" style={{color: '#f68716'}}/>
                            : ''
                          }
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {jobLegsData?.length === 0 ?
                <p className='d-flex justify-content-center'>There are no Jobs to display</p>
                : ''
              }
            </div>
          </UILoader>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {JobLegsPage}
