import React, { useState } from 'react'
import { ModalHeader } from 'react-bootstrap'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap'
import { JobService } from '../../modules/Services/Job'
import toast from 'react-hot-toast'

function CopyBookingModal({ id, bookingData, openCopyModal, setOpenCopyModal }) {
    const entity_id = localStorage.getItem('entity_id')
    const [copyJobs, setCopyJobs] = useState(false)
    const [loader, setLoader] = useState(false)

    const submitCopyJobs = () => {
        setLoader(true)

        const data = {
            is_copy_jobs: copyJobs ? 1 : 0
        }
        JobService.copyJobs(entity_id, id, data)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setOpenCopyModal(false)
        })
        .catch((err: any) => toast.error(err?.response?.data?.message))
        .finally(() => setLoader(false))
    }

    return (
        <Modal isOpen={openCopyModal} toggle={() => setOpenCopyModal(false)} className={`modal-dialog-centered modal-md`}>
            <ModalHeader className='d-flex justify-content-center'>
                <span style={{fontSize:'19px'}}>Copy Booking</span>
            </ModalHeader>
            <ModalBody>
                <Row className="my-10">
                    <div>
                        <span style={{fontSize:'14px'}}>Are you sure you wish to copy booking ID {bookingData?.booking_number}?</span>
                    </div>
                    <div className='mt-5 d-flex align-items-center'>
                        <Input type="checkbox" checked={copyJobs} onChange={(e) => setCopyJobs(e.target.checked)} />
                        <Label className='ms-5'>Copy Jobs</Label>
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button type="button" color="secondary" className="me-3 btn-sm" onClick={() => setOpenCopyModal(false)}>
                        Cancel
                    </Button>
                    <Button type='submit' color='primary' className='btn-sm' disabled={loader} onClick={submitCopyJobs}>
                        {loader ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Copy</span>}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CopyBookingModal