import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import { selectStyle, selectThemeColors } from '../../modules/commonModel/Utils';
import toast from 'react-hot-toast';
import { JobLegsService } from '../../modules/Services/JobLegs';
import moment from 'moment';
import { DriverService } from '../../modules/Services/Drivers';
import { VehicleService } from '../../modules/Services/Vehicles';
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames';
import classnames from 'classnames';
import { LocationService } from '../../modules/Services/Location';

function EditEventModel({ 
    openEditModel= false,
    setOpenEditModel,
    editEvent,
    statusData,
    render,
    setRender,
    edit_drag
}) {

    const dafualtData = {
        job_number : null,
        id: null,
        driver_vehicles_id: null,
        driver: { value: '', label: 'Select Driver' },
        vehicle: { value: '', label: 'Select vehicle' },
        // pickUp: { value: '', label: 'Select PickUp' },
        // dropOff: { value: '', label: 'Select DropOff' },
        pick_up_location: null,
        drop_off_location: null,
        status: { value: '', label: 'Select Status' },
        note: '',
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null
    }
    const { control,handleSubmit,reset, setValue, getValues, formState: { errors }} = useForm()
    const entity_id = localStorage.getItem('entity_id')
    // const [currentDriver, setCurrentDriver] = useState({ value: '', label: 'Select Driver' })
    // const [currentVehicle, setCurrentVehicle] = useState({ value: '', label: 'Select Vehicle' })
    const [eventData, setEventData] = useState(dafualtData)
    const [loader, setLoader] = useState(false)
    // const [currentPickUp, setCurrentPickUp] = useState({ value: '', label: 'Select PickUp' })
    // const [currentDropOff, setCurrentDropOff] = useState({ value: '', label: 'Select DropOff' })
    // const [currentStatus, setCurrentStatus] = useState({ value: '', label: 'Select Status' })
    // const [currentNote, setCurrentNote] = useState('')
    // const [startDate, setStartDate] = useState()
    // const [endDate, setEndDate] = useState()
    // const [startTime, setStartTime] = useState(null)
    // const [endTime, setEndTime] = useState(null)
    let driverTimeoutId: ReturnType<typeof setTimeout> | null = null;
    let vahicleTimeoutId: ReturnType<typeof setTimeout> | null = null;
    let locationTimeoutId: ReturnType<typeof setTimeout> | null = null;

    function onSubmit(formData: any) {
        setLoader(true)
        const data = {
            job_id: eventData?.id,
            new_driver: eventData?.driver?.value ? eventData?.driver?.value : null,
            new_vehicle: eventData?.vehicle?.value ? eventData?.vehicle?.value : null,
            driver_vehicles_id: eventData?.driver_vehicles_id,
            // pick_up: eventData?.pickUp?.value,
            // drop_off: eventData?.dropOff?.value,
            pick_up_location: formData?.pick_up_location,
            drop_off_location: formData?.drop_off_location,
            status: eventData?.status?.value,
            // start_at: startDate ? typeof startDate === 'string' ? startDate : moment(startDate).format('YYYY-MM-DD HH:mm') : null,
            // end_at: endDate ? typeof endDate === 'string' ? endDate : moment(endDate).format('YYYY-MM-DD HH:mm') : null,
            start_at_date: eventData?.startDate ? typeof eventData?.startDate === 'string' ? eventData?.startDate : moment(eventData?.startDate).format('YYYY-MM-DD') : null,
            start_at_time: eventData?.startTime,
            end_at_date: eventData?.endDate ? typeof eventData?.endDate === 'string' ? eventData?.endDate : moment(eventData?.endDate).format('YYYY-MM-DD') : null,
            end_at_time: eventData?.endTime,
            notes: eventData?.note,
            is_edit_drag: edit_drag
        }
        JobLegsService.updatePlannerJob(entity_id, data)
        .then((res: any) => {
          toast.success(res?.data?.message)
          setOpenEditModel(false)
          setRender(!render)
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }
    
    function editEventData() {
        const obj1 = {
            pick_up_location: null,
            drop_off_location: null,
          }
        reset(obj1);
        JobLegsService.editIndividualJob(entity_id, editEvent?.event?._def?.extendedProps?.driver_vehicles_id)
        .then((res: any) => {
            setEventData(res?.data?.response)

            let currentStatus = { value: '', label: 'Select Status' };
            statusData?.map((item: any) => {
                if (res?.data?.response?.status === item?.value) {
                    currentStatus = item;
                }
            })
            setEventData({
                ...res?.data?.response,
                driver: { value: res?.data?.response?.driver_id || '', label: res?.data?.response?.driver_name },
                vehicle: { value: res?.data?.response?.vehicle_id || '', label: res?.data?.response?.vehicle_name },
                // pickUp: { value: res?.data?.response?.pick_up, label: res?.data?.response?.pickup },
                // dropOff: { value: res?.data?.response?.drop_off, label: res?.data?.response?.dropoff },
                pick_up_location: res?.data?.response?.pick_up_location,
                drop_off_location: res?.data?.response?.drop_off_location,
                startDate: res?.data?.response?.start_at_date,
                startTime: res?.data?.response?.start_at_time,
                endDate: res?.data?.response?.end_at_date,
                endTime: res?.data?.response?.end_at_time,
                note: res?.data?.response?.notes || '',
                status: currentStatus
            })

            const obj = {
                pick_up_location: res?.data?.response?.pick_up_location,
                drop_off_location: res?.data?.response?.drop_off_location,
              }
            reset(obj);

            // setCurrentDriver({ value: res?.data?.response?.driver_id || '', label: res?.data?.response?.driver_name })
            // setCurrentVehicle({ value: res?.data?.response?.vehicle_id || '', label: res?.data?.response?.vehicle_name })
            // setCurrentPickUp({ value: res?.data?.response?.pick_up, label: res?.data?.response?.pickup })
            // setCurrentDropOff({ value: res?.data?.response?.drop_off, label: res?.data?.response?.dropoff })
            // setStartDate(res?.data?.response?.start_at_date)
            // setStartTime(res?.data?.response?.start_at_time)
            // setEndDate(res?.data?.response?.end_at_date)
            // setEndTime(res?.data?.response?.end_at_time)
            // setCurrentNote(res?.data?.response?.notes || '')
            // statusData?.map((item: any) => {
            //     if (res?.data?.response?.status === item?.value) {
            //         setCurrentStatus(item)
            //     }
            // })
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message);
        });
    }

    const loadLocationOptions = (inputValue: any, callback: any) => {
        if (locationTimeoutId) {
          clearTimeout(locationTimeoutId);
        }
        if (inputValue?.length > 3) {
            locationTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,location_name',
                location_name : inputValue
            }
            LocationService.getLocationList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                return { value: item?.id, label: item?.location_name }
                }))
            })
            .catch((err: any) => console.log(err?.response?.data?.message))
            }, 1000)
        }
    }

    useEffect(() => {
        if (openEditModel) {
            editEventData()
        } else {
            setEventData(dafualtData)
            // setCurrentVehicle({ value: '', label: 'Select Vehicle' })
            // setCurrentDriver({ value: '', label: 'Select Driver' })
            // setCurrentPickUp({ value: '', label: 'Select PickUp' })
            // setCurrentDropOff({ value: '', label: 'Select DropOff' })
            // setCurrentStatus({ value: '', label: 'Select Status' })
            // setStartDate(null)
            // setEndDate(null)
            // setCurrentNote('')
        }
    }, [openEditModel])

    const loadDriverOptions = (inputValue: any, callback: any) => {
        if (driverTimeoutId) {
            clearTimeout(driverTimeoutId);
        }
        driverTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,driver_name',
                driver_name : inputValue
            }
            DriverService.getDriverList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.driver_name}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000);
    }

    const loadVahicleOptions = (inputValue: any, callback: any) => {
        if (vahicleTimeoutId) {
            clearTimeout(vahicleTimeoutId);
        }
        vahicleTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,vehicle_name',
                vehicle_name : inputValue
            }
            VehicleService.getVehicleList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.vehicle_name}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

  return (
    <>
        <Modal className="modal-dialog-centered modal-lg" isOpen={openEditModel} toggle={() => setOpenEditModel(false)}>
            <ModalBody className="p-10">
                <div className="text-center pb-10" >
                    <h1 className="">{eventData?.job_number}</h1>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pt-5">
                        <Col md={6} xs={12} className='mb-5'>
                        <Label className='form-label' for='pick_up_location'>Pick Up Location</Label>
                        <Controller name='pick_up_location' control={control} defaultValue='' rules={{required : false}}
                            render={({ field }) => ( 
                            <Input placeholder='Enter Pick Up Location' invalid={errors.pick_up_location && false} 
                            // value={eventData?.pick_up_location}
                            {...field}
                             />)
                            
                            } 
                            />
                            {errors.pick_up_location && (<small className="text-danger ml-2">Pick up location is a required field</small>)}
                        </Col>
                        <Col md={6} xs={12} className='mb-5'>
                        <Label className='form-label' for='drop_off_location'>Drop Off Location</Label>
                        <Controller name='drop_off_location' control={control} defaultValue='' rules={{required : false}}
                            render={({ field }) => ( 
                            <Input placeholder='Enter Drop Off Location' invalid={errors.drop_off_location && false} 
                            // value={eventData?.drop_off_location}
                            {...field}
                             />)} 
                            />
                            {errors.drop_off_location && (<small className="text-danger ml-2">Drop off location is a required field</small>)}
                        </Col>
                        {/* <Col md={6} xs={12} className="mb-1">
                            <Label className="form-label">Pickup Location</Label>
                            <AsyncSelect
                                isClearable={true}
                                className='react-select w-100'
                                classNamePrefix='select'
                                placeholder='Search Pickup'
                                isRtl={false}
                                name='callback-react-select'
                                loadOptions={loadLocationOptions}
                                value={eventData?.pickUp}
                                cacheOptions
                                onChange={(data: any) => {
                                    // setCurrentPickUp(data)
                                    setEventData({
                                        ...eventData,
                                        pickUp: data
                                    })
                                }}
                                noOptionsMessage={() => 'No options'}
                            />
                        </Col>
                        <Col md={6} xs={12} className="mb-1">
                            <Label className="form-label">Dropoff Location</Label>
                            <AsyncSelect
                                isClearable={true}
                                className='react-select w-100'
                                classNamePrefix='select'
                                placeholder='Search Dropoff'
                                isRtl={false}
                                name='callback-react-select'
                                loadOptions={loadLocationOptions}
                                value={eventData?.dropOff}
                                cacheOptions
                                onChange={(data: any) => {
                                    // setCurrentDropOff(data)
                                    setEventData({
                                        ...eventData,
                                        dropOff: data
                                    })
                                }}
                                noOptionsMessage={() => 'No options'}
                            />
                        </Col> */}
                    </Row>
                    <Row className="my-10">
                        <Col md={4} xs={12} className="mb-1">
                            <Label className="form-label">Start</Label>
                            <div className='d-flex'>
                                <Col md='7'>    
                                    <Flatpickr
                                        className='form-control' 
                                        placeholder='Select Start Date'
                                        value={eventData?.startDate}
                                        options={{ 
                                            enableTime: false, 
                                            altInput: true, 
                                            dateFormat: 'Y-m-d H:i',
                                            disableMobile : true,
                                            altInputClass: classNames(
                                            'form-control flatpickr-input'
                                            ) 
                                        }} 
                                        onChange={(value: any) => {
                                            // setStartDate(value[0])
                                            setEventData({
                                                ...eventData,
                                                startDate: value[0]
                                            })
                                        }}
                                    />
                                </Col>
                                <Col md='5'>
                                    <Input type='time' className='cursor-pointer' value={eventData?.startTime} 
                                        onChange={(e) => 
                                            // setStartTime(e.target.value)
                                            setEventData({
                                                ...eventData,
                                                startTime: e.target.value
                                            })
                                        } 
                                    />
                                </Col>
                            </div>
                        </Col>
                        <Col md={4} xs={12} className="mb-1">
                            <Label className="form-label">End</Label>
                            <div className='d-flex'>
                                <Col md='7'>    
                                    <Flatpickr 
                                        className='form-control' 
                                        placeholder='Select End Date'
                                        value={eventData?.endDate}
                                        options={{ 
                                            enableTime: false, 
                                            altInput: true, 
                                            dateFormat: 'Y-m-d H:i',
                                            disableMobile : true,
                                            minDate: eventData?.startDate,
                                            altInputClass: classnames(
                                            'form-control flatpickr-input'
                                            ) 
                                        }} 
                                        onChange={(value: any) => {
                                            // setEndDate(value[0])
                                            setEventData({
                                                ...eventData,
                                                endDate: value[0]
                                            })
                                        }}
                                    />
                                </Col>
                                <Col md='5'>
                                    <Input type='time' className='cursor-pointer' value={eventData?.endTime} 
                                        onChange={(e) => 
                                            // setEndTime(e.target.value)
                                            setEventData({
                                                ...eventData,
                                                endTime: e.target.value
                                            })
                                        } 
                                    />
                                </Col>
                            </div>
                        </Col>
                        <Col md={4} xs={12} className="mb-1">
                            <Label className="form-label">Status</Label>
                            <ReactSelect
                                isClearable={false}
                                className='react-select'
                                classNamePrefix='select'
                                theme={selectThemeColors}
                                options={statusData}
                                value={eventData?.status}
                                onChange={(data: any) => {
                                    // setCurrentStatus(data)
                                    setEventData({
                                        ...eventData,
                                        status: data
                                    })
                                }}
                            />         
                        </Col>
                    </Row>
                    <Row className='my-10'>
                        <h6 className="form-label">Notes</h6>
                        <Input type='textarea' className='mx-2' rows='2' value={eventData?.note} 
                            onChange={(e) => 
                                // setCurrentNote(e.target.value)
                                setEventData({
                                    ...eventData,
                                    note: e.target.value
                                })
                            } 
                        />
                    </Row>
                    <Row>
                        <Col md={6} xs={12} className="mb-1">
                            <Label className="form-label">Driver</Label>
                            <AsyncSelect
                                isClearable={true}
                                className='react-select w-100'
                                classNamePrefix='select'
                                placeholder='Search Driver'
                                isRtl={false}
                                name='callback-react-select'
                                loadOptions={loadDriverOptions}
                                value={eventData?.driver}
                                cacheOptions
                                onChange={(data: any) => {
                                    // setCurrentDriver(data)
                                    setEventData({
                                        ...eventData,
                                        driver: data
                                    })
                                }}
                            />
                        </Col>
                        <Col md={6} xs={12} className="mb-1">
                            <Label className="form-label">Vehicle</Label>
                            <AsyncSelect
                                isClearable={true}
                                className='react-select w-100'
                                classNamePrefix='select'
                                placeholder='Search Vehicle'
                                isRtl={false}
                                name='callback-react-select'
                                loadOptions={loadVahicleOptions}
                                value={eventData?.vehicle}
                                cacheOptions
                                onChange={(data: any) => {
                                    // setCurrentVehicle(data)
                                    setEventData({
                                        ...eventData,
                                        vehicle: data
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="mt-10">
                            <div className='d-flex justify-content-end'>
                                <Button type="button" className="btn btn-secondary" onClick={() => setOpenEditModel(false)}>
                                    Close
                                </Button>
                                <Button color="primary" type="submit" className="ms-3" disabled={loader}>
                                    { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
          </ModalBody>
        </Modal>
    </>
  )
}

export default EditEventModel