import {Suspense, createContext, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
// import "flatpickr/dist/themes/material_green.css";

// sentry
import * as Sentry from "@sentry/react";

export const FilterDetails = createContext(null)

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  const [filterValue, setFilterValue] = useState({
    jobs: {
      job_search: {},
      customer: {},
      status: {},
      driver: {},
      tag: {},
      date: [null, null]
    },
    bookings: {
      booking_search: {},
      customer: {},
      status: {},
      tag: {},
      date: [null, null]
    },
    customers: {
      customer_search: {}
    },
    drivers: {
      driver_search: {}
    },
    vehicles: {
      vehicle_search: {}
    },
    locations: {
      location_search: {}
    },
    planner: {
      date: {}
    },
    invoice: {
      invoice_search: {},
      customer: {},
      date: {},
      status: {}
    }
  })

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <FilterDetails.Provider value={[filterValue, setFilterValue]}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
      </FilterDetails.Provider>
    </Suspense>
  )
}

export {App}
