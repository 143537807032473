import React, { Fragment, useEffect, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner } from "reactstrap"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import toast from 'react-hot-toast'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import { VehicleService } from '../../modules/Services/Vehicles'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'

const AddVehicle = () => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm()
  const {id} = useParams()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [centeredModal, setCenteredModal] = useState(false)
  const [pending, setPending] = useState(false)
  const [editLoader, setEditLoader] = useState(false)
  const [notDisplayPlanner, setNotDisplayPlanner] = useState(false)
  const entity_id = localStorage.getItem('entity_id')

  function fetchFormData(data: any) {
    setLoader(true)
    const obj = {
      entity_id: entity_id,
      vehicle_name: data?.name,
      is_not_display_on_planner: +notDisplayPlanner
    }
    if (id) delete obj.entity_id

    VehicleService[id ? 'updateVehicle' : 'storeVehicle'](entity_id, obj, id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        navigate('/vehicle')
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoader(false))
  }

  function editData() {
    setEditLoader(true)
    VehicleService.editVehicle(entity_id, id)
      .then((res: any) => {
        const obj = {
          name: res.data?.response?.vehicle_name
        }
        setNotDisplayPlanner(res.data?.response?.is_not_display_on_planner)
        reset(obj)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setEditLoader(false))
  }

  useEffect(() => {
    if (id) {
      editData()
    }
  }, [id])

  function forceDeleteVehicle() {
    setPending(true)
    VehicleService.deleteVehicle(entity_id, id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setCenteredModal(false)
        navigate("/vehicle")
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setPending(false))
  }

  return (
    <>
        <Fragment>
          <ConfirmModal
            isOpen={centeredModal}
            onHide={() => setCenteredModal(false)}
            ondelete={forceDeleteVehicle}
            loader={pending}
          />
          <Card className='p-10'>
            <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
              <Form onSubmit={handleSubmit(fetchFormData)}>
                <Row>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='name'>Name</Label>
                    <Controller name='name' control={control} rules={{required: true}} defaultValue=''
                        render={({ field }) => <Input placeholder='Enter Name' invalid={ errors.name && true } {...field} />} 
                    />
                    {errors.name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                  </Col>
                  <Col md= '12' className='mb-10'>
                    <Input type='checkbox' className='me-5' checked={notDisplayPlanner} onChange={(e) => setNotDisplayPlanner(e.target.checked)} />
                    <Label className='form-label'>Do not display on Planner</Label>
                  </Col>
                </Row>
                <div className='d-flex justify-content-between'>
                  <div>
                    { id &&
                      <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                        Delete
                      </Button>
                    }
                  </div>
                  <div>
                    <Button type="button" color="secondary" onClick={() => navigate("/vehicle")} style={{ marginRight: "10px" }}>
                      Close
                    </Button>
                    <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                      { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                    </Button>
                  </div>
                </div>
              </Form>
            </UILoader>
          </Card>
        </Fragment>
    </>
  )
}

export {AddVehicle}