import React, { useEffect, useState } from 'react'
import { ModalBody } from 'react-bootstrap'
import { Button, Col, Label, Modal, Row, Spinner } from 'reactstrap'
import AsyncSelect from 'react-select/async'
import { DriverService } from '../../modules/Services/Drivers';
import { VehicleService } from '../../modules/Services/Vehicles';
import toast from 'react-hot-toast';
import { JobLegsService } from '../../modules/Services/JobLegs';

function ModifyResourceModel({ modifyResource, setModifyResource, modifyResourceData, vehicleList, driverList, events, render, setRender }) {
  const entity_id = localStorage.getItem('entity_id')
  const [currentDriver, setCurrentDriver] = useState({ value: '', label: 'Select Driver' })
  const [currentVehicle, setCurrentVehicle] = useState({ value: '', label: 'Select Vehicle' })
  const [loader, setLoader] = useState(false)
  let driverTimeoutId: ReturnType<typeof setTimeout> | null = null;
  let vahicleTimeoutId: ReturnType<typeof setTimeout> | null = null;

  const loadDriverOptions = (inputValue: any, callback: any) => {
    if (driverTimeoutId) {
      clearTimeout(driverTimeoutId);
    }
    driverTimeoutId = setTimeout(() => {
      const obj = { 
        getall: 1,
        fields: 'id,driver_name',
        driver_name : inputValue
      }
      DriverService.getDriverList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.driver_name}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000);
  }

  const loadVahicleOptions = (inputValue: any, callback: any) => {
    if (vahicleTimeoutId) {
      clearTimeout(vahicleTimeoutId);
    }
    vahicleTimeoutId = setTimeout(() => {
      const obj = { 
        getall: 1,
        fields: 'id,vehicle_name',
        vehicle_name : inputValue
      }
      VehicleService.getVehicleList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.vehicle_name}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000)
  }
    
  function saveMultipleChange() {
    setLoader(true)
    let driver_vehicles_id = [];
    events?.map((item: any) => {
      if (item?.resourceId === modifyResourceData) {
        driver_vehicles_id.push(item?.extendedProps?.driver_vehicles_id)
      }
    })
    const obj = {
      // job_ids: booking_id,
      driver_vehicles_ids: driver_vehicles_id,
      new_driver: currentDriver?.value,
      new_vehicle: currentVehicle?.value,
      // old_driver: modifyResourceData ? modifyResourceData?.id?.split('_')[0] === 'null' ? null : modifyResourceData?.id?.split('_')[0] : null,
      // old_vehicle: modifyResourceData ? modifyResourceData?.id?.split('_')[1] === 'null' ? null : modifyResourceData?.id?.split('_')[1] : null
    }
    JobLegsService.updatePlannerJob(entity_id, obj)
    .then((res: any) => {
      toast.success(res?.data?.message);
      setModifyResource(false)
      setRender(!render)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message);
    })
    .finally(() => setLoader(false))
  }

  useEffect(() => {
    if (modifyResource) {
      driverList?.map((item: any) => {
        if (item?.value == (modifyResourceData ? modifyResourceData?.split('_')[0] : null)) {
          setCurrentDriver(item)
        }
      })
      vehicleList?.map((item: any) => {
        if (item?.value == (modifyResourceData ? modifyResourceData?.split('_')[1] : null)) {
          setCurrentVehicle(item)
        }
      })
      // setCurrentDriver({ value: modifyResourceData?.id ? modifyResourceData?.id?.split('_')[0] : null, label: modifyResourceData?.extendedProps?.driver })
      // setCurrentVehicle({ value: modifyResourceData?.id ? modifyResourceData?.id?.split('_')[1] : null, label: modifyResourceData?.extendedProps?.vehicle })
    } else {
      setCurrentDriver({ value: '', label: 'Select Driver' })
      setCurrentVehicle({ value: '', label: 'Select Vehicle' })
    }
  }, [modifyResource])
  
  return (
    <Modal isOpen={modifyResource} toggle={() => setModifyResource(false)} className="modal-dialog-centered modal-md">
      <ModalBody>
        <div className='text-center'>
          <h4>Multiple Change</h4>
        </div>
        <Row className='my-10'>
          <Col md={6} xs={12} className="mb-1">
            <Label className="form-label">Driver</Label>
              <AsyncSelect
                isClearable={true}
                className='react-select w-100'
                classNamePrefix='select'
                placeholder='Search Driver'
                isRtl={false}
                name='callback-react-select'
                loadOptions={loadDriverOptions}
                value={currentDriver}
                cacheOptions
                onChange={(data: any) => {
                    setCurrentDriver(data)
                }}
              />
          </Col>
          <Col md={6} xs={12} className="mb-1">
            <Label className="form-label">Vehicle</Label>
            <AsyncSelect
              isClearable={true}
              className='react-select w-100'
              classNamePrefix='select'
              placeholder='Search Vehicle'
              isRtl={false}
              name='callback-react-select'
              loadOptions={loadVahicleOptions}
              value={currentVehicle}
              cacheOptions
              onChange={(data: any) => {
                  setCurrentVehicle(data)
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='d-flex justify-content-end'>
              <Button type="button" className="btn btn-secondary" onClick={() => setModifyResource(false)}>
                  Close
              </Button>
              <Button color="primary" type="submit" className="ms-3" disabled={loader}>
                  { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none' onClick={saveMultipleChange}>Update</span>}
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModifyResourceModel