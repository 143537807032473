import React, { useState, useEffect, useMemo, useRef, Fragment, useContext } from 'react'
import { Card, Input, Label, Spinner } from 'reactstrap'
import toast from 'react-hot-toast';
import moment from 'moment'
import Flatpickr from 'react-flatpickr';
import ReactSelect from "react-select"

// ** calender 
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
// ** services 
import { ScheduleService } from '../../modules/Services/Resources';
import { selectThemeColors } from '../../modules/commonModel/Utils';
import { JobLegsService } from '../../modules/Services/JobLegs';
import EditEventModel from './EditEventModel';
import { Tooltip } from 'bootstrap'
import ModifyResourceModel from './ModifyResourceModel';
import { DriverService } from '../../modules/Services/Drivers';
import { VehicleService } from '../../modules/Services/Vehicles';
import { FilterDetails } from '../../App';

export default function ResourceBoard() {
  const entity_id = localStorage.getItem('entity_id')

  const resourceAreaColumns = [
    {
      group: true,
      field: 'driver',
      headerContent: 'Driver'
    },
    {
      field: 'vehicle',
      headerContent: 'Vehicle'
    }
  ]  // title[menu] of resourceboard

  const [filterValue, setFilterValue] = useContext<any>(FilterDetails)
  const [resourcesList, setResourcesList] = useState([])  // list of driver & vehicle
  const [events, setEvents] = useState([])  // list of Events
  
  const [date, setDate] = useState()
  const [isOpenDatePicker, setOpenDatePicker] = useState(false)
  const [loader, setLoader] = useState('calendar')
  const [currentDropValue, setCurrentDropValue] = useState({ value: 'driver_name', label: 'Order By Driver' })
  const [currentDropType, setCurrentDropType] = useState({ value: 'ASC', label: 'Asc' })
  const [openEditModel, setOpenEditModel] = useState(false)
  const [editEvent, setEditEvent] = useState(null)
  const [render, setRender] = useState(false)
  const [unallocated_drivers, setUnallocated_drivers] = useState(false)
  const [unallocated_vehicles, setUnallocated_vehicles] = useState(false)
  const [edit_drag, setEdit_drag] = useState(false)
  const [modifyResource, setModifyResource] = useState(false)
  const [modifyResourceData, setModifyResourceData] = useState(null)
  const [vehicleList, setVehicleList] = useState([])
  const [driverList, setDriverList] = useState([])
  const [statusData, setStatusData] = useState([])
  // const [calendarView, setCalendarView] = useState('resourceTimeline')
  const calendarView = useRef('resourceTimeline')
  const [currentMonth, setCurrentMonth] = useState()
  const [moreLinkClick, setMoreLinkClick] = useState(false)
  const [fistTimeApiCall, setFistTimeApiCall] = useState(false)
  const calendarRef = useRef()
  const flatpickerRef = useRef()
  const countRef = useRef(0)
  const tooltipRef = useRef({})
  const timeoutRef = useRef(null);
  const loadingEventId = useRef(null);
  const calendarApi = (calendarRef.current as any)?.getApi();

  const dropList = [
    { value: 'driver_name', label: 'Order By Driver' },
    { value: 'vehicle_name', label: 'Order By Vehicle' }
  ]

  const dropListType = [
    { value: 'ASC', label: 'Asc' },
    { value: 'DESC', label: 'Desc' }
  ]

  const viewOptions = {
    resourceTimeline: {
      buttonText: 'Day',
    },
    dayGridMonth: {
      buttonText: 'Month',
    }
  };

  useEffect(() => {
    if (date) {
      setLoader('calendar')
      if (calendarView.current === 'resourceTimeline') getResource()
      else getMonthResource()
    }
  }, [date, currentMonth, currentDropValue, currentDropType, unallocated_vehicles, unallocated_drivers, calendarView.current])

  useEffect(() => {
    if (date) {
      setLoader('jobChange')
      if (calendarView.current === 'resourceTimeline') getResource()
      else getMonthResource()
    }
  }, [render])

  const prepareResource = (vehicle: any, driver: any) => {
    return { 
      id: (driver?.driver_id || vehicle?.id) ? `${driver?.driver_id}_${vehicle?.id}` : null,  // resourceId[should be unique] = ['driver_id'_'vehicle_id'] 
      eventClassNames: 'resource-event', 
      driver: driver?.driver_name, 
      vehicle: vehicle?.vehicle_name
    }
  }

  const prepareUnallocatedResource = (item: any) => {
    return { 
      id: item?.driver_name ? item?.id+'_null' : 'null_'+item?.id,  // resourceId[should be unique] = ['driver_id'_'vehicle_id'] 
      eventClassNames: 'resource-event', 
      driver: item?.driver_name, 
      vehicle: item?.vehicle_name
    }
  }

  const prepareEvent = (data: any, driver: any, vehicle: any, index: any) => {
    return {
      // id: `${driver?.driver_id}_${vehicle?.id}_${index}`, // used in: [tooltip_id]
      id: data?.driver_vehicles_id, // used in: [tooltip_id]
      resourceId: (driver?.driver_id || vehicle?.id) ? `${driver?.driver_id}_${vehicle?.id}` : null, // used: [link with resource]
      title: data?.pick_up_location,
      start: moment(data?.start_at_date + " " + data?.start_at_time).format(),
      end: moment(data?.end_at_date + " " + data?.end_at_time).format(),
      classNames: ['event-normal'],
      droppable:true,
      dragging:true,
      editable: true,
      drag:true, // Allow the event's start time to be edited
      eventDurationEditable: false,
      eventOverlap: true,
      color: data?.color,
      extendedProps: {
        customer_name: data?.customer_name,
        pick_up_location: data?.pick_up_location,
        drop_off_location: data?.drop_off_location,
        status: data?.status,
        booking_id: data?.event_id,
        cost: data?.cost,
        // job_leg_name: data?.job_leg_name,
        tooltip: data?.tooltip,
        vehicles_required: data?.vehicles_required,
        driver_vehicles_id: data?.driver_vehicles_id,
        notes: data?.notes
      }
    }
  }

  function handleViewChange(event: any) {
    // setCalendarView(event?.view?.type)
    calendarView.current = event?.view?.type;
    if (event?.view?.type === 'resourceTimeline') setDate(Object.keys(filterValue?.planner?.date)?.length ? filterValue?.planner?.date : event?.view?.currentStart)
    else setCurrentMonth(event?.view?.currentStart)
  }

  useEffect(() => {
    if (calendarApi && Object.keys(filterValue?.planner?.date)?.length) {
      calendarApi.gotoDate(Object.keys(filterValue?.planner?.date)?.length ? filterValue?.planner?.date : new Date()); // Navigates calendar to the desired date
    }
  }, [fistTimeApiCall, calendarApi]);

  const getMonthResource = () => {
    // setResourcesList([])
    // setEvents([])
    const payload = {
      date: moment(currentMonth).format('YYYY-MM')
    }
    ScheduleService.getMonthlyResources(entity_id, payload)
    .then((res:any) => {
      const events =  res?.data?.response?.map((item: any) => {
        return {
          id: item?.driver_vehicles_id, // used in: [tooltip_id]
          // resourceId: (item?.driver_id || item?.id) ? `${item?.driver_id}_${item?.id}` : null, // used: [link with resource]
          title: item?.pickup, 
          start: item?.start_at_date + " " + item?.start_at_time,
          end: item?.end_at_date + " " + item?.end_at_time,
          classNames: 'resource-event',
          editable: false,
          extendedProps: { 
            tooltip: item?.calendar_data,
            pick_up_location: item?.calendar_data,
            driver_vehicles_id: item?.driver_vehicles_id,
            background: item?.color || 'black'
          }
        }
      }) 
      setEvents(events)
    })
    .catch((err:any) => {
      toast.error(err?.message)
    })
    .finally(() => setLoader(''))
  }

  const getResource = () => {
    // setResourcesList([])
    // setEvents([])
    const payload = {
      start_at: moment(date).format('YYYY-MM-DD'),
      order_by: currentDropValue?.value + '|' + currentDropType?.value,
      unallocated_vehicles: unallocated_vehicles ? unallocated_vehicles : null,
      unallocated_drivers: unallocated_drivers ? unallocated_drivers : null
    }
    ScheduleService.getResources(entity_id, payload)
    .then((res:any) => {
      const data = res?.data?.response?.both
      const data_null = res?.data?.response?.both_null

      const unallocated_drivers = res?.data?.response?.unallocated_drivers
      const unallocated_vehicle = res?.data?.response?.unallocated_vehicles
      const resources = []
      const evenets = []
      const data1 = []
      const data2 = []
      
      data_null?.map((item: any) => {
        item?.vehicles?.map((vehicle: any) => {
          const resObj = prepareResource(vehicle, item)
          resources.push(resObj)
          vehicle?.events?.map((evt: any, index: any) => {
            const evtObj = prepareEvent(evt, item, vehicle, index)
            evenets.push(evtObj)
          })
        })
      })

      data?.map((item: any) => {
        item?.vehicles?.map((vehicle: any) => {
          const resObj = prepareResource(vehicle, item)
          resources.push(resObj)
          vehicle?.events?.map((evt: any, index: any) => {
            const evtObj = prepareEvent(evt, item, vehicle, index)
            evenets.push(evtObj)
          })
        })
      })

      unallocated_vehicle?.map((item: any) => {
        const resVehicle = prepareUnallocatedResource(item)
        data1.push(resVehicle)
      })

      unallocated_drivers?.map((item: any) => {
        const resDriver = prepareUnallocatedResource(item)
        data2.push(resDriver)
      })

      setResourcesList([...resources, ...data1, ...data2])
      setEvents(evenets)
    })
    .catch((err:any) => {
      toast.error(err?.message)
    })
    .finally(() => { setLoader(''); loadingEventId.current = null })
  }

  const eventContentRender = (event: any) => {
    const isLoading = loadingEventId.current === event?.id;

    return (
      <div className='d-flex'>
        {/* <li className="event" data-tooltip-id={`evt-tooltip-${event?.id}`}></li> */}
        {isLoading && <span className='me-1'><Spinner size={'sm'} /></span>}
        <span className="fc-event-title fc-sticky">{ event?.title }</span>  
        {/* <span>{ `${moment(event?.start).format('hh:mm')} / ${moment(event?.end).format('hh:mm')}` }</span> */}
        {/* <span>{ `${ event?.extendedProps?.pick_up_location}` }</span> */}
        {/* <span>{ event?.extendedProps?.status?.label }</span> */}
      </div>
    )
  }

  const handleDateChange = (selectedDates: any, dateStr: any, instance: any) => {
    setDate(dateStr)
    setOpenDatePicker(false)
    // const calendarApi = (calendarRef.current as any)?.getApi();
    calendarApi.gotoDate(dateStr)
    calendarView.current === 'resourceTimeline' && setFilterValue({...filterValue, planner: {...filterValue?.planner, date: dateStr }})
  }

  const PickDate = useMemo(() => {
   return(
     isOpenDatePicker &&
     <div className="date-picker parent-container-datepicker-range_date">
      <Flatpickr
        ref={flatpickerRef}
        onChange={(selectedDates, dateStr, instance) => {
          handleDateChange(selectedDates, dateStr, instance)
        }}
        value={calendarView.current === 'resourceTimeline' ? moment(date).format('YYYY-MM-DD') : moment(currentMonth).format('MMMM, YYYY')}
        options={{
          enableTime: false,
          altInput: true,
          dateFormat: "Y-m-d H:i",
          altInputClass:"full-calander",  
          position:'auto center',
          disableMobile : true,
          inline: isOpenDatePicker
        }}
      />
      </div>
   )
  }, [isOpenDatePicker])

  useEffect(() => {
    const listener = (event: any) => {
      if (!event?.target?.closest('.parent-container-datepicker-range_date')) {
        setOpenDatePicker(false)
      }
    }
    document.addEventListener("mousedown", listener)
    return () => {
      document.removeEventListener("mousedown", listener)
    }
  }, [flatpickerRef])

  const handleNextPrevClick = (info: any) => {
    if (fistTimeApiCall) {
      if (calendarView.current === 'resourceTimeline') {
        setDate(info?.startStr )
        setFilterValue({...filterValue, planner: {...filterValue?.planner, date: info?.startStr }})
      } else {
        setCurrentMonth(info?.view?.currentStart)
      }
    }
    setFistTimeApiCall(true)
  }

  const dropSort = (
    <div className="schedule-dropdown d-flex col-md-8">
      <div className='d-flex'>
        <ReactSelect
          className="react-select me-3"
          classNamePrefix="select"
          isClearable={false}
          isSearchable={false}
          menuPlacement="auto"
          value={currentDropValue}
          options={dropList}
          theme={selectThemeColors}
          onChange={(data) => {
            setCurrentDropValue(data);
          }}
        />
        <ReactSelect
          className="react-select"
          classNamePrefix="select"
          isClearable={false}
          isSearchable={false}
          menuPlacement="auto"
          value={currentDropType}
          options={dropListType}
          theme={selectThemeColors}
          onChange={(data) => {
            setCurrentDropType(data);
          }}
        />
      </div>
      <div className='mx-5 d-flex align-items-center'>
        <Input type='checkbox' className='me-5' checked={unallocated_drivers} onChange={(e) => setUnallocated_drivers(e.target.checked)} />
        <label className='mb-0'>Display Unallocated Drivers</label>
      </div>
      <div className='d-flex align-items-center'>
        <Input type='checkbox' className='me-5' checked={unallocated_vehicles} onChange={(e) => setUnallocated_vehicles(e.target.checked)} />
        <label className='mb-0'>Display Unallocated Vehicles</label>
      </div>
      <div className='ms-5 d-flex align-items-center'>
        <Input type='checkbox' className='me-5' checked={edit_drag} onChange={(e) => setEdit_drag(e.target.checked)} />
        <label className='mb-0'>Edit on Drag</label>
      </div>
    </div>
  );

  const handleEventDrag = (e: any) => { 
    loadingEventId.current = e.event.id;
    if (tooltipRef.current[e.event.id]) {
      tooltipRef.current[e.event.id].dispose(); // Dispose of the tooltip to remove it
      delete tooltipRef.current[e.event.id]; // Delete the reference to the tooltip
    }
    const obj = {
      job_id: e?.event?._def?.extendedProps?.booking_id,
      start_at_date: e.event.start ? moment(e.event.start).format('YYYY-MM-DD') : null,
      start_at_time: e.event.start ? moment(e.event.start).format('HH:mm') : null,
      end_at_date: e.event.end ? moment(e.event.end).format('YYYY-MM-DD') : moment(e.event.start).format('YYYY-MM-DD'),
      end_at_time: e.event.end ? moment(e.event.end).format('HH:mm') : moment(e.event.start).format('HH:mm'),
      new_driver: e.event._def.resourceIds[0].split('_')?.length > 1 ? e.event._def.resourceIds[0].split('_')[0] === 'null' ? null : e.event._def.resourceIds[0].split('_')[0] : null,
      new_vehicle: e.event._def.resourceIds[0].split('_')?.length > 1 ? e.event._def.resourceIds[0].split('_')[1] === 'null' ? null : e.event._def.resourceIds[0].split('_')[1] : null,
      driver_vehicles_id: e?.event?._def?.extendedProps?.driver_vehicles_id,
      notes: e?.event?._def?.extendedProps?.notes,
      is_edit_drag: edit_drag
    };
    JobLegsService.updatePlannerJob(entity_id, obj)
      .then((res: any) => {
        toast.success(res?.data?.message);
        setRender(!render)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
  }

  const editEventClick = (data: any) => {
    setEditEvent(data)
    setOpenEditModel(true)
  }

  function getDriverList() {
    const obj = { 
      getall: 1,
      fields: 'id,driver_name'
    }
    DriverService.getDriverList(entity_id, obj)
    .then((res: any) => {
      const data = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.driver_name}
      })
      setDriverList(data)
    })
    .catch(err => console.log(err?.response?.data?.message))
  }

  function getVehicleList() {
    const obj = { 
      getall: 1,
      fields: 'id,vehicle_name'
    }
    VehicleService.getVehicleList(entity_id, obj)
    .then((res: any) => {
      const data = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.vehicle_name}
      })
      setVehicleList(data)
    })
    .catch(err => console.log(err?.response?.data?.message))
  }

  function getStatusList() {
    JobLegsService.jobLegStatus(entity_id)
    .then((res: any) => {
      const statusList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.label }
      })
      setStatusData(statusList)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }
  
  useEffect(() => {
    getDriverList()
    getVehicleList()
    getStatusList()
  }, [])

  useEffect(() => {
    const handleClick = (event: any) => {
      const cell = event.currentTarget;
      if (!cell.classList.contains('fc-resource-group')) {
        const resourceId = cell.getAttribute('data-resource-id');

        countRef.current += 1;

        // Clear the previous timeout if it exists
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Set a new timeout
        timeoutRef.current = setTimeout(() => {
          if (countRef.current === 2) {
            setModifyResource(true);
            setModifyResourceData(resourceId);
          }
          // Reset count after handling double click
          countRef.current = 0;
        }, 500);
      }
    };

    const resourceColumns = document.querySelectorAll('.fc-datagrid-cell');
    resourceColumns.forEach((cell) => {
      cell.classList.add('cursor-pointer');
      cell.addEventListener('click', handleClick);
    });

    // Cleanup function to remove event listeners and clear timeout
    return () => {
      resourceColumns.forEach((cell) => {
        cell.removeEventListener('click', handleClick);
      });
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  });  

  // const handleResourceClick = (resource: any) => {
  //   countRef.current = countRef.current + 1

  //   const delayDebounceFn = setTimeout(() => {
  //     if (countRef.current == 2) {
  //       setModifyResource(true)
  //       setModifyResourceData(resource?._resource)
  //     }
  //     countRef.current = 0;
  //   }, 500);
  //   return () => clearTimeout(delayDebounceFn)
  // };

  const eventDidMount = (info: any) => {
    if (info.event.extendedProps.background) {
      info.el.style.background = info.event.extendedProps.background;
      info.el.style.borderColor = info.event.extendedProps.background;
      info.el.style.color = 'white';
    }
    if (!moreLinkClick && tooltipRef.current[info.event.id]) {
      tooltipRef.current[info.event.id].dispose(); // Dispose of the tooltip to remove it
      delete tooltipRef.current[info.event.id]; // Delete the reference to the tooltip
    }
    const tooltip = new Tooltip(info.el, {
      title: info.event.extendedProps.tooltip?.split("|").join("<br />"),
      placement: 'top',
      trigger: 'hover',
      container: 'body',
      html: true
    })
    tooltipRef.current[info.event.id] = tooltip; // Store tooltip instance
    setMoreLinkClick(false)
  }

  const handleEventDragStart = (info: any) => {
    if (tooltipRef.current[info.event.id]) {
      tooltipRef.current[info.event.id].dispose(); // Dispose of the tooltip to remove it
      delete tooltipRef.current[info.event.id]; // Delete the reference to the tooltip
    }
  }  

  return (
    <Fragment>
      <ModifyResourceModel
        modifyResource={modifyResource} 
        setModifyResource={setModifyResource} 
        modifyResourceData={modifyResourceData}
        vehicleList={vehicleList}
        driverList={driverList}
        events={events}
        render={render}
        setRender={setRender}
      />
      <EditEventModel 
        openEditModel={openEditModel} 
        setOpenEditModel={setOpenEditModel} 
        editEvent={editEvent} 
        statusData={statusData}
        render={render}
        setRender={setRender}
        edit_drag={edit_drag}
      />
      <Card className="p-10 calender-container">
        { loader === 'calendar' && 
          <div className="calender-loader">
            <Spinner 
              color="primary" 
              style={{
                height: '3rem',
                width: '3rem'
              }}
            /> 
          </div>
        }
        <div className={`calender-box ${loader === 'calendar' ? 'bg-blur' : ''}`}>
          <div>
            { PickDate }
          </div>
          {calendarView.current === 'resourceTimeline' &&
            <div className="ms-1"> {dropSort} </div>
          }
          <FullCalendar 
            ref={calendarRef}
            plugins={[resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
            slotMinTime="00:00"
            slotMaxTime="23:59"
            customButtons = {{
              myCustomButton: {
                text: calendarView.current === 'resourceTimeline' ? moment(date).format('MMMM D, YYYY') : moment(currentMonth).format('MMMM, YYYY'),
                click: function(event) {
                  setOpenDatePicker(!isOpenDatePicker)
                }
              }
            }}
            headerToolbar={{
              left: '',
              right:`resourceTimeline,dayGridMonth today prev myCustomButton next`
            }}
            views={viewOptions}
            initialView= 'resourceTimeline'
            resourceAreaWidth='25%'
            resourceAreaColumns={resourceAreaColumns}
            resourceOrder= {'vehicle_name, driver_name'} //sorting resource based on vehicle_name or driver_name
            resources={resourcesList}
            // resourceLabelDidMount={(info) => {
            //   info.el.addEventListener('click', () => handleResourceClick(info.resource));
            //   info.el.classList.add('cursor-pointer');
            // }}
            eventDragStart={handleEventDragStart}
            eventContent={(arg) => eventContentRender(arg?.event)}
            events={events}
            datesSet={handleNextPrevClick} 
            eventClick={editEventClick}
            eventDrop={handleEventDrag}
            droppable={true}
            editable={true}
            eventResize={handleEventDrag}
            eventDidMount={eventDidMount}
            viewDidMount={handleViewChange}
            eventResizableFromStart={true}
            // dayMaxEvents={2}
            moreLinkClick={() => setMoreLinkClick(true)}
            eventOverlap={true}
            resourceAreaHeaderContent={"Resources"}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          />
        </div>
      </Card>
    </Fragment>
  )
}
