import axios from "axios"

function getDriverList(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}drivers`, { headers: { 'Entity-Id': entity_id },  params : param})
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function storeDriver(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_APP_API_URL}drivers/store`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function updateDriver(entity_id: any, param: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.put(`${import.meta.env.VITE_APP_API_URL}drivers/${id}/update`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function editDriver(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}drivers/${id}/edit`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function deleteDriver(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.delete(`${import.meta.env.VITE_APP_API_URL}drivers/${id}/delete`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

export const DriverService = {
    getDriverList,
    storeDriver,
    updateDriver,
    editDriver,
    deleteDriver
}