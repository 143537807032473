import React, { useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader, CardText, Spinner, Button } from "reactstrap"

function ConfirmModal({
  ondelete = () => {},
  ondeletebutton = () => {},
  title = "Are you sure?",
  body = "You won't be able to revert this!",
  loader = false,
  disabled = false,
  no = 'No, Cancel',
  yes = 'Yes, Delete',
  editNote = 'add',
  titleAlign = false,
  onlyTitle = false,
  deleted = '',
  size = '',
  fill = false,
  appendTo = 'body',
  closeButton = 'danger',
  addButton = 'primary',
  ...props
}) {
  const [centeredModal, setCenteredModal] = useState(false)

  return (
    <>
      <Modal {...props} container={appendTo} toggle={() => setCenteredModal(!centeredModal)} className={`modal-dialog-centered ${titleAlign && 'modalsize'} ${size}`}>
        <ModalHeader className={`bg-white ${(titleAlign || onlyTitle) && 'd-flex justify-content-center'}`} style={{borderBottom: 'none'}}>
          <CardText style={{fontSize:'19px'}}>{title}</CardText>
        </ModalHeader>
        <ModalBody className="py-0">
          <CardText style={{fontSize:'15px'}} className={`${titleAlign && 'text-center'}`}>{body}</CardText>
          </ModalBody>
        <ModalFooter style={{border : "none"}}>
          <div className="d-flex justify-content-end col-12">
            {
              no && fill ? <Button color={closeButton} onClick={() => props.onHide()}>{no}</Button> : <button type="button" style={{color : "#7468F0"}} className="btn text-blue bg-blue" onClick={() => props.onHide()}>{no}</button>
            }
            {
              yes && (fill ? <Button color={addButton} className="ms-2" onClick={ondelete}>{yes}</Button> : <button  type="button" style={{color : "#7468F0"}} className="btn text-blue bg-blue" onClick={ondelete} disabled={loader}> {loader ? <Spinner size='sm' /> : yes }</button>)
            }
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ConfirmModal

