import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { CustomersPage } from './CustomersPage'

const CustomersPageWrapper: FC = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>Customers</PageTitle> */}
      <CustomersPage />
    </>
  )
}

export default CustomersPageWrapper
