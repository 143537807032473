import React, { Fragment, useEffect, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner, Modal, CardText } from "reactstrap"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import Flatpickr from 'react-flatpickr'
import classnames from 'classnames'
import toast from 'react-hot-toast'
import { JobLegsService } from '../../modules/Services/JobLegs'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable';
import { preventDefualt, selectStyle, selectThemeColors } from '../../modules/commonModel/Utils'
import { JobService } from '../../modules/Services/Job'
import { VehicleService } from '../../modules/Services/Vehicles'
import { LocationService } from '../../modules/Services/Location'
import { DriverService } from '../../modules/Services/Drivers'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import { AddLocation } from '../Locations/AddLocation'
import { TagsService } from '../../modules/Services/Tags'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import AsyncCreatableSelect from 'react-select/async-creatable';

const AddJobLeg = () => {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm()
    const {id} = useParams()
    const addMode = !id
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [currentJob, setCurrentJob] = useState({ value: '', label: 'Select Booking'})
    const [statusData, setStatusData] = useState([])
    const [currentStatus, setCurrentStatus] = useState({ value: 1, label: 'New' })
    const [currentPickUp, setCurrentPickUp] = useState({ value: '', label: 'Select PickUp' })
    const [currentDropOff, setCurrentDropOff] = useState({ value: '', label: 'Select DropOff' })
    const [currentPickUpLocation, setCurrentPickUpLocation] = useState(null)
    const [currentDropOffLocation, setCurrentDropOffLocation] = useState(null)
    const [booking_id, setBooking_id] = useState(null)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [centeredModal, setCenteredModal] = useState(false)
    const [pending, setPending] = useState(null)
    const entity_id = localStorage.getItem('entity_id')
    const [searchParams] = useSearchParams()
    const [notifyDriverModal, setNotifyDriverModal] = useState(false)
    const [notifyDriver, setNotifyDriver] = useState([])
    const [editDataList, setEditDataList] = useState(null)
    const [openLocation, setOpenLocation] = useState(false)
    const [locationType, setLocationType] = useState(null)
    const [newLocationData, setNewLocationData] = useState(null)
    const [vehicle_required, setVehicle_required] = useState(1)
    const [preventDriver, setPreventDriver] = useState([])
    const [preventVehicle, setPreventVehicle] = useState([])
    const [editLoader, setEditLoader] = useState(false)
    const [editTags, setEditTags] = useState([])

    let locationTimeoutId: ReturnType<typeof setTimeout> | null = null;
    
    const { fields, append } = useFieldArray({
      control,
      name: "allocation"
    });

    const styles = {
      option : (baseStyles: any, state: any) => ({
        ...baseStyles,
        backgroundColor : state.isDisabled ? '#e6e6e6' : '',
        color : state.isDisabled ? '#b9b9c3' : '',
        cursor : state.isDisabled ? 'not-allowed' : ''
      })
    }
    
    const defaultDriver = {label : 'Select Driver', value : null}
    const defaultVehicle = {label : 'Select Vehicle', value : null}
    const defaultAllocationForm = {
      driver : defaultDriver,
      vehicle : defaultVehicle
    }

    let driverTimeoutId: ReturnType<typeof setTimeout> | null = null;
    let vahicleTimeoutId: ReturnType<typeof setTimeout> | null = null;
    let bookingTimeoutId: ReturnType<typeof setTimeout> | null = null;

    const [tagData, setTagData] = useState([])
    const [currentTag, setCurrentTag] = useState<TagOption[]>([])
    type TagOption = {
      label: string;
      value: string;
    };

    useEffect(() => {
      const job =  searchParams.get('booking')
      setBooking_id(job)
    }, [searchParams])
    
    function fetchFormData(data: any) {
      setLoader(true)
      const allcationData = data?.allocation?.map((item: any) => {
        return { driver_id: item?.driver?.value || null, vehicle_id: item?.vehicle?.value || null, driver_vehicles_id: item?.driver_vehicles_id || null }
      })

      const obj = {
        entity_id: entity_id,
        booking_id: data?.booking_id?.value,
        // start_at: startDate ? typeof startDate === 'string' ? startDate : moment(startDate).format('YYYY-MM-DD HH:mm') : null,
        // end_at:  endDate ? typeof endDate === 'string' ? endDate : moment(endDate).format('YYYY-MM-DD HH:mm') : null,
        start_at_date: startDate ? typeof startDate === 'string' ? startDate : moment(startDate).format('YYYY-MM-DD') : null,
        end_at_date: endDate ? typeof endDate === 'string' ? endDate : moment(endDate).format('YYYY-MM-DD') : null,
        start_at_time: startTime,
        end_at_time: endTime,
        // pick_up: data?.pickUp?.value,
        // drop_off: data?.dropOff?.value,
        pick_up_location: data?.pick_up_location?.value,
        drop_off_location: data?.drop_off_location?.value,    
        cost: data?.cost,    
        pax: data?.pax,
        vehicles_required: data?.vehicles_required,
        status: data?.status?.value,
        tags: currentTag?.map((item) => { return item?.value }),
        notes: data?.notes,
        driver_vehicles_arr: allcationData
      }
      if (id) delete obj.entity_id
      JobLegsService[id ? 'updateJobLeg' : 'storeJobLeg'](entity_id, obj, id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        if (booking_id) navigate(`/booking/${booking_id}/edit`)
        else navigate('/jobs')
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoader(false))
    }

    function getTagList() {
      const data = {
        getall: 1,
        fields: 'id,name'
      }
      TagsService.getTagsList(entity_id, data)
      .then((res: any) => {
        const tagsList = res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.name }
        })
        setTagData(tagsList)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
    }

    useEffect(() => {
      if (tagData?.length && editTags?.length) {
        setCurrentTag(editTags)
      }
    }, [tagData, editTags])

    function editData() {
      setEditLoader(true)
      JobLegsService.editJobLeg(entity_id, id)
      .then((res: any) => {
        const array = []
        res?.data?.response?.driver_vehicles_arr?.map((item: any) => {
          array.push({ driver: { value: item?.driver?.driver_id, label: item?.driver?.driver_name }, vehicle: { value: item?.vehicle?.vehicle_id, label: item?.vehicle?.vehicle_name }, driver_vehicles_id: item?.driver_vehicles_id })
        })

        const obj = {
          job_id: res?.data?.response?.job_number,
          // name: res?.data?.response?.job_leg_name,
          booking_id: { value: res?.data?.response?.booking_id, label: res?.data?.response?.booking_number },
          // pickUp: { value: res?.data?.response?.pick_up, label: res?.data?.response?.pick_up_location},
          // dropOff: { value: res?.data?.response?.drop_off, label: res?.data?.response?.drop_off_location},
          pick_up_location: { value: res?.data?.response?.pick_up_location, label: res?.data?.response?.pick_up_location},
          drop_off_location:{ value: res?.data?.response?.drop_off_location, label: res?.data?.response?.drop_off_location},
          cost: res?.data?.response?.cost,
          pax: res?.data?.response?.pax,
          vehicles_required: res?.data?.response?.vehicles_required || 1,
          notes: res?.data?.response?.notes,
          allocation: array?.length ? array : [defaultAllocationForm]
        }

        const selAllocationDriver = []
        const selAllocationVehicle = []
        const arr = res?.data?.response?.driver_vehicles_arr
        const allocationFormResArr = arr?.map((item: any, i: any) => {
          selAllocationDriver.push({label : item?.driver?.driver_name, value : item?.driver?.driver_id})
          selAllocationVehicle.push({label : item?.driver?.vehicle_name, value : item?.driver?.vehicle_id})
          return {
            driver : item?.driver ? {label : item?.driver?.driver_name, value : item?.driver?.driver_id } : defaultDriver,
            vehicle : item?.vehicle ? {label : item?.vehicle?.vehicle_name, value: item?.vehicle?.vehicle_id} : defaultVehicle,
          }
        })
        setPreventDriver(selAllocationDriver)
        setPreventVehicle(selAllocationVehicle)
        append(allocationFormResArr?.length ? allocationFormResArr : [defaultAllocationForm])

        const driver_ids = []
        selAllocationDriver?.filter((item) => { 
          if (item?.value) {
            driver_ids.push(item?.label)
          }
        })
        setNotifyDriver(driver_ids)

        const data = res?.data?.response?.tags?.map((data: any) => {
          return { value: data?.id, label: data?.name }
        })
        setEditTags(data)
        setStartDate(res?.data?.response?.start_at_date)
        setStartTime(res?.data?.response?.start_at_time)
        setEndDate(res?.data?.response?.end_at_date)
        setEndTime(res?.data?.response?.end_at_time)
        // setNotifyDriver({ value: res?.data?.response?.driver_id, label: res?.data?.response?.driver_name })
        setEditDataList(res?.data?.response)
        setCurrentJob({ value: res?.data?.response?.booking_id, label: res?.data?.response?.booking_number })
        // setCurrentPickUp({ value: res?.data?.response?.pick_up, label: res?.data?.response?.pick_up_location})
        // setCurrentDropOff({ value: res?.data?.response?.drop_off, label: res?.data?.response?.drop_off_location})
        setCurrentPickUpLocation(res?.data?.response?.pick_up_location)
        setCurrentDropOffLocation(res?.data?.response?.drop_off_location)
        setVehicle_required(res?.data?.response?.vehicles_required)

        reset(obj)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setEditLoader(false))
    }

    useEffect(() => {
      if (id) editData()
    }, [id])

    useEffect(() => {
      if (!locationType && editDataList && statusData?.length > 0) {
        const obj = getValues()

        statusData?.map((item) => {
          if (editDataList?.status?.id === item?.value) {
            obj.status = item
            setCurrentStatus(item)
          }
        })
        reset(obj)
      }
    }, [editDataList, statusData])

    

    useEffect(() => {
      if (addMode) {
        const booking = JSON.parse(localStorage.getItem('booking'))
        if(booking_id == booking?.value) {
          setValue('booking_id', booking)
          setCurrentJob(booking)
        }
      }
    }, [booking_id])

    const loadBookingOptions = (inputValue: any, callback: any) => {
      if (bookingTimeoutId) {
        clearTimeout(bookingTimeoutId);
      }
      bookingTimeoutId = setTimeout(() => {
        const obj = { 
          getall: 1,
          fields: 'id,booking_number',
          booking_number : inputValue
        }
        JobService.getJobList(entity_id, obj)
        .then((res: any) => {
          callback(res?.data?.map((item: any) => {
            return { value: item?.id, label: item?.booking_number }
          }))
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
      }, 1000)
    }

    const loadDriverOptions = (inputValue: any, callback: any) => {
      if (driverTimeoutId) {
        clearTimeout(driverTimeoutId);
      }
      driverTimeoutId = setTimeout(() => {
        const obj = { 
          getall: 1,
          fields: 'id,driver_name',
          driver_name : inputValue
        }
        DriverService.getDriverList(entity_id, obj)
        .then((res: any) => {
          callback(res?.data?.response?.map((item: any) => {
            return { value: item?.id, label: item?.driver_name}
          }))
        })
        .catch(err => console.log(err?.response?.data?.message))
      }, 1000)
    }

    const loadVahicleOptions = (inputValue: any, callback: any) => {
      if (vahicleTimeoutId) {
        clearTimeout(vahicleTimeoutId);
      }
      vahicleTimeoutId = setTimeout(() => {
        const obj = { 
          getall: 1,
          fields: 'id,vehicle_name',
          vehicle_name : inputValue
        }
        VehicleService.getVehicleList(entity_id, obj)
        .then((res: any) => {
          callback(res?.data?.response?.map((item: any) => {
            return { value: item?.id, label: item?.vehicle_name}
          }))
        })
        .catch(err => console.log(err?.response?.data?.message))
      }, 1000)
    }

    function getStatusList() {
      JobLegsService.jobLegStatus(entity_id)
      .then((res: any) => {
        const statusList = res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.label }
        })
        setStatusData(statusList)
        setValue('status', { value: 1, label: 'New' })
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
    }

    const loadLocationOptions = (inputValue: any, callback: any) => {
      if (locationTimeoutId) {
        clearTimeout(locationTimeoutId);
      }
      // if (inputValue?.length > 3) {
        locationTimeoutId = setTimeout(() => {
          const obj = { 
            name : inputValue
          }
          LocationService.getSuggestLocationList(entity_id, obj)
          .then((res: any) => {
            callback(res?.data?.response?.map((item: any) => {
              return { value: item?.location_name, label: item?.location_name }
            }))
          })
          .catch((err: any) => console.log(err?.response?.data?.message))
        }, 1000)
      // }
    }

    useEffect(() => {
      getStatusList()
      getTagList()
      append(defaultAllocationForm)
    }, [])

    function forceDeleteJobLeg() {
      setPending('delete_jobLeg')
      JobLegsService.deleteJobLeg(entity_id, id)
        .then((res: any) => {
          toast.success(res?.data?.message)
          setCenteredModal(false)
          navigate('/jobs')
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.message)
        })
        .finally(() => setPending(false))
    }

    function forceNotfiyDriver() {
      setPending('notify_driver')
      const data = {
        job_ids: [id]
      }
      JobLegsService.sendSms(entity_id, data)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setNotifyDriverModal(false)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setPending(null))
    }

    window.addEventListener('closeLocationModal', async () => {
      if (localStorage.getItem('closeLocationModal')) {
        await setOpenLocation(false)
        localStorage.removeItem('closeLocationModal')
      }
    }, false)

    useEffect(() => {
      if (locationType === 'pick_up') {
        setValue('pickUp', { value: newLocationData?.id, label: newLocationData?.location_name})
        setCurrentPickUp({ value: newLocationData?.id, label: newLocationData?.location_name})
      } else if (locationType === 'drop_off') {
        setValue('dropOff', { value: newLocationData?.id, label: newLocationData?.location_name})
        setCurrentDropOff({ value: newLocationData?.id, label: newLocationData?.location_name})
      }
    }, [newLocationData])

    function manipulateAllocation(value: any) {
      if (value - fields?.length > 0) {
        const Arr: any = []
        for(let i=1; i <= value - fields?.length; i++) {
          Arr.push(defaultAllocationForm)
          // append(defaultAllocationForm)
        }
        setValue('allocation', [...fields, ...Arr])
      } else if (value - fields?.length === 0) {
      } else if (value === 0) {
        setValue('allocation', [])
      } else {
        const Arr = fields
        Arr.splice(value - fields?.length);
        setValue('allocation', Arr)
      }
    }

    const handleSelectedDriverAndVehicle = (field: any) => {
      if (field === 'driver') {
        const selQuoteStatusArr = fields?.map((item: any) => item?.driver)
        setPreventDriver(selQuoteStatusArr)
      } else {
        const selQuoteStatusArr = fields?.map((item: any) => item?.vehicle)
        setPreventVehicle(selQuoteStatusArr)
      }
    }

    // document.addEventListener('DOMContentLoaded', function() {
    //   var timeInputs = document.querySelectorAll('input[type="time"]');
    //   timeInputs.forEach(function(input) {
    //     console.log('dgdfghdifgndfji')
    //       input.addEventListener('mousedown', function(event) {
    //           event.preventDefault();
    //       });
    //   });
    // });
    

    // const timeInput = document.querySelector('input[type="time"]');
    // if (timeInput) {
    //   timeInput.addEventListener('click', function(event: any) {
    //       // Get the bounding box of the input
    //       const rect = timeInput.getBoundingClientRect();
  
    //       // Check if the click happened on the icon (usually on the right side)
    //       if (event.clientX > (rect.right - 30)) {  // 30px is a rough estimate for the icon's width
    //           console.log('Time icon clicked');
    //           event.preventDefault();
    //           // Your logic for icon click
    //       } else {
    //           console.log('Time input clicked, not the icon');
    //           // Your logic for input click
    //       }
    //   });
    // }

  return (
    <>
      <Fragment>
        <Modal isOpen={openLocation} toggle={() => setOpenLocation(false)} className='modal-dialog-centered modal-lg'>
          <AddLocation setNewLocationData={setNewLocationData} />
        </Modal>
        <ConfirmModal
          isOpen={notifyDriverModal}
          onHide={() => setNotifyDriverModal(false)}
          ondelete={forceNotfiyDriver}
          title= 'Notify Driver'
          body={`Do you wish to notify ${notifyDriver.join(', ')} of this Job`}
          no = 'No'
          yes = 'Yes'
          loader={pending === 'notify_driver'}
        />
        <ConfirmModal
          isOpen={centeredModal}
          onHide={() => setCenteredModal(false)}
          ondelete={forceDeleteJobLeg}
          loader={pending === 'delete_jobLeg'}
        />
        <Card className='p-10'>
          <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
            <Form onSubmit={handleSubmit(fetchFormData)}>
              <Row>
                {id &&
                  <Col md= '4' className='mb-5'>
                    <Label className='form-label' for='name'>Job ID</Label>
                    <Controller name='job_id' control={control} rules={{required: true}} defaultValue=''
                      render={({ field }) => <Input placeholder='Enter Job Id' disabled invalid={ errors.job_id && true } {...field} />} 
                    />
                    {errors.job_id && (<small className="text-danger ml-2 ">Job Id is a required field</small>)}
                  </Col>
                }
                <Col md='4' className='mb-5'>
                  <Label className='form-label' for='booking_id'>Booking ID</Label>
                  <Controller name='booking_id' control={control} rules={{required: true}} defaultValue=''
                    render={({ field: { onChange } }) => 
                      <AsyncSelect
                        isClearable={true}
                        className='react-select w-100'
                        classNamePrefix='select'
                        placeholder='Search Booking'
                        isRtl={false}
                        name='callback-react-select'
                        loadOptions={loadBookingOptions}
                        styles={errors.booking_id && selectStyle}
                        value={currentJob}
                        cacheOptions
                        onChange={(data: any) => {
                          onChange(data)
                          setCurrentJob(data)
                        }}
                        noOptionsMessage={() => 'No options'}
                      />
                    }
                  />
                  {errors?.booking_id && (<small className="text-danger ml-2 ">Job is a required field</small>)}
                </Col>
                <Col md='4' className='mb-5'>
                  <Label className='form-label' for='status'>Status</Label>
                  <Controller name='status' control={control} defaultValue='' rules={{required : true}}
                    render={({ field: {onChange} }) => ( 
                      <ReactSelect
                        isClearable={false}
                        className='react-select'
                        classNamePrefix='select'
                        theme={selectThemeColors}
                        options={statusData}
                        value={currentStatus}
                        styles={errors.status && selectStyle}
                        onChange={(data: any) => {
                          onChange(data)
                          setCurrentStatus(data)
                        }}
                      />
                    )} 
                  />
                  {errors.status && (<small className="text-danger ml-2">Status is a required field</small>)}
                </Col>
                <Col md='4' className='mb-5'>
                  <Label className='form-label' for='start'>Job Start</Label>
                  <div className='d-flex'>
                    <Col md='8' style={{marginRight: '5px'}}>
                      <Flatpickr
                        className='form-control' 
                        placeholder='Select Start Date'
                        // key={errors.start ? errors.start.toString() : ''}
                        value={startDate}
                        options={{ 
                          enableTime: false, 
                          altInput: true, 
                          dateFormat: 'Y-m-d H:i',
                          disableMobile : true,
                          altInputClass: classnames(
                            'form-control flatpickr-input',
                            { 'is-invalid' : !!errors.start }
                          ) 
                        }} 
                        onChange={(value) => {
                          setStartDate(value[0])
                          setEndDate(value[0])
                        }}
                      />
                    </Col>
                    <Col md='4'>
                      <Input type='time' className='cursor-pointer' value={startTime} onChange={(e) => { setStartTime(e.target.value); setEndTime(e.target.value)}} />
                    </Col>
                  </div>
                </Col>
                <Col md='4' className='mb-5'>
                  <Label className='form-label' for='end'>Job End</Label>
                  <div className='d-flex'>
                    <Col md='8' style={{marginRight: '5px'}}>
                      <Flatpickr 
                        className='form-control' 
                        placeholder='Select End Date'
                        // key={errors.end ? errors.end.toString() : ''}
                        value={endDate}
                        options={{ 
                          enableTime: false, 
                          altInput: true, 
                          dateFormat: 'Y-m-d H:i',
                          disableMobile : true,
                          minDate: startDate,
                          altInputClass: classnames(
                            'form-control flatpickr-input',
                            { 'is-invalid' : !!errors.end }
                          ) 
                        }} 
                        onChange={(value) => {
                          setEndDate(value[0])
                        }}
                      />
                    </Col>
                    <Col md='4'>
                      <Input type='time' className='cursor-pointer' value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                    </Col>
                  </div>
                </Col>
                {/* <Col md='4' className='mb-5'>
                  <div className='d-flex justify-content-between'>
                    <Label className='form-label' for='start'>Pick Up Location</Label>
                    <a className='link' style={{textDecoration: 'underLine'}} onClick={() => { setLocationType('pick_up'); setOpenLocation(true) }}>Add Location</a>
                  </div>
                  <Controller name='pickUp' control={control} rules={{required: true}} defaultValue=''
                    render= {({ field: { onChange } }) => (
                      <AsyncSelect
                        isClearable={true}
                        className='react-select w-100'
                        classNamePrefix='select'
                        placeholder='Search From'
                        isRtl={false}
                        name='callback-react-select'
                        loadOptions={loadLocationOptions}
                        value={currentPickUp}
                        cacheOptions
                        onChange={(data: any) => {
                            onChange(data)
                            setCurrentPickUp(data)
                        }}
                        noOptionsMessage={() => 'No options'}
                      />
                    )}
                  /> 
                  {errors?.pickUp && (<small className="text-danger ml-2 ">Pick Up Location is a required field</small>)}
                </Col> */}
               <Col md='4' className='mb-5'>
                  <Label className='form-label' for='pick_up_location'>Pick Up Location</Label>
                  <Controller name='pick_up_location' control={control} defaultValue='' rules={{required : true}}
                    render={({ field }) => ( 
                      <AsyncCreatableSelect
                        id='task-tags'
                        {...field}
                        className='react-select'
                        classNamePrefix='select'
                        isClearable={false}
                        isSearchable={true}
                        loadOptions={loadLocationOptions}
                        theme={selectThemeColors}
                        onChange={(data: any) => {
                          field.onChange(data)
                        }}
                      />
                      // <Input placeholder='Enter Pick Up Location' invalid={errors.pick_up_location && true} 
                      //   value={currentPickUpLocation}
                      //   {...field} 
                      // />
                    )} 
                  />
                  {errors.pick_up_location && (<small className="text-danger ml-2">Pick up location is a required field</small>)}
                </Col>
                <Col md='4' className='mb-5'>
                  <Label className='form-label' for='drop_off_location'>Drop Off Location</Label>
                  <Controller name='drop_off_location' control={control} defaultValue='' rules={{required : true}}
                    render={({ field }) => ( 
                      <AsyncCreatableSelect
                        id='task-tags'
                        {...field}
                        className='react-select'
                        classNamePrefix='select'
                        isClearable={false}
                        isSearchable={true}
                        loadOptions={loadLocationOptions}
                        theme={selectThemeColors}
                        onChange={(data: any) => {
                          field.onChange(data)
                        }}
                      />
                      // <Input placeholder='Enter Drop Off Location' invalid={errors.drop_off_location && true} 
                      //   value={currentDropOffLocation}
                      //   {...field} 
                      // />
                    )} 
                  />
                  {errors.drop_off_location && (<small className="text-danger ml-2">Drop off location is a required field</small>)}
                </Col>
                {/* <Col md='4' className='mb-5'>
                  <div className='d-flex justify-content-between'>
                    <Label className='form-label' for='dropOff'>Drop Off Location</Label>
                    <a className='link' style={{textDecoration: 'underLine'}} onClick={() => { setLocationType('drop_off'); setOpenLocation(true) }}>Add Location</a>
                  </div>
                  <Controller name='dropOff' control={control} rules={{required: true}} defaultValue=''
                    render= {({ field: { onChange } }) => (
                      <AsyncSelect
                        isClearable={true}
                        className='react-select w-100'
                        classNamePrefix='select'
                        placeholder='Search From'
                        isRtl={false}
                        name='callback-react-select'
                        loadOptions={loadLocationOptions}
                        value={currentDropOff}
                        cacheOptions
                        onChange={(data: any) => {
                            onChange(data)
                            setCurrentDropOff(data)
                        }}
                        noOptionsMessage={() => 'No options'}
                      />
                    )}
                  /> 
                  {errors?.dropOff && (<small className="text-danger ml-2 ">Drop Off Location is a required field</small>)}
                </Col> */}
                <Col md='4' className='mb-5'>
                  <Label className='form-label' for='cost'>Job Charge</Label>
                  <Controller name='cost' control={control} defaultValue='' rules={{required : true}}
                    render={({ field }) => ( 
                      <Input placeholder='Enter Cost' disabled={editDataList?.booking_status?.id === 2} invalid={errors.cost && true} {...field} />)} 
                    />
                    {errors.cost && (<small className="text-danger ml-2">Job Charge is a required field</small>)}
                </Col>
                <Col md='4' className='mb-5'>
                  <Label className='form-label' for='pax'>PAX</Label>
                  <Controller name='pax' control={control} rules={{required : false}} defaultValue=''
                    render={({ field }) => ( 
                      <Input placeholder='Enter PAX' type='number' min={0}  
                        onKeyDown={(e) => preventDefualt(e)}
                        {...field} />)} 
                    />
                </Col>
                <Col md='4' className='mb-5'>
                  <Label className='form-label' for='cost'>Vehicles Required</Label>
                  <Controller name='vehicles_required' control={control} defaultValue={1} rules={{required : true}}
                    render={({ field: { onChange, ...field } }) => ( 
                      <Input placeholder='Enter Vehicles Required' min={1} type='number' invalid={errors.vehicles_required && true} 
                        value={vehicle_required}
                        onKeyDown={(e) => preventDefualt(e)}
                        onFocus={(e) => (e.target as any).select()}
                        onChange={(e) => { 
                          if (e.target.value === '') {
                            e.preventDefault();
                            return false;
                          }
                          onChange(e)
                          setVehicle_required(Number(e.target.value))
                          manipulateAllocation(Number(e.target.value))
                      }} 
                      {...field} />)} 
                    />
                    {errors.vehicles_required && (<small className="text-danger ml-2">Vehicles Required is a required field</small>)}
                </Col>
                <Col md='4' className='mb-5'>
                    <Label className='form-label' for='tag'>Tag</Label>
                    {/* <ReactSelect
                      isMulti
                      id='task-tags'
                      className='react-select booking_zindex'
                      classNamePrefix='select'
                      isClearable={false}
                      isSearchable={false}
                      options={tagData}
                      theme={selectThemeColors}
                      value={currentTag}
                      onChange={data => {
                        setCurrentTag(data as TagOption[])
                      }}
                    /> */}

                  <CreatableSelect 
                      isMulti 
                      id='task-tags'
                      className='react-select booking_zindex'
                      classNamePrefix='select'
                      isClearable={false}
                      isSearchable={true}
                      options={tagData}
                      theme={selectThemeColors}
                      value={currentTag}
                      onChange={data => {
                        setCurrentTag(data as TagOption[])
                      }}
                  />
                  </Col>
                <Row>
                  <Col md='8' className='mb-5'>
                    <Label className='form-label' for='cost'>Job Notes</Label>
                    <Controller name='notes' control={control} defaultValue='' rules={{required : false}}
                      render={({ field }) => ( 
                        <Input type='textarea' rows='2' style={{resize: 'none'}} placeholder='Enter Notes' {...field} />)} 
                      />
                      {errors.notes && (<small className="text-danger ml-2">Job Notes is a required field</small>)}
                  </Col>
                </Row>
                <div className='mb-5'>
                  <Label>Allocation</Label>
                  <Row className='my-4'>
                    <Col xs={2} lg={2} className="qs-head">
                        <CardText>Driver</CardText>
                    </Col>
                    <Col xs={2} lg={2} className="qs-head">
                        <CardText>Vehicle</CardText>
                    </Col>
                  </Row>
                  <div style={{ height: '300px', overflowY: 'scroll', overflowX: 'hidden' }}>
                    { fields?.length > 0 ? fields?.map((item, index) => (
                      <Row key={item?.id} className="mb-3">
                        <Col xs={2} lg={2}>
                          <Controller name={`allocation.${index}.driver`} control={control} defaultValue={defaultDriver}
                            render={({ field }) => (
                              <AsyncSelect
                                {...field} 
                                isClearable={true}
                                className='react-select w-100'
                                classNamePrefix='select'
                                placeholder='Search Driver'
                                isRtl={false}
                                name='callback-react-select'
                                loadOptions={loadDriverOptions}
                                cacheOptions
                                onChange={(data: any) => {
                                  field.onChange(data)
                                  handleSelectedDriverAndVehicle('driver')
                                }}
                                isOptionDisabled = {(option) => {
                                  return preventDriver?.some((elem) => elem?.value === option?.value)
                                }}
                                styles={styles}
                              />
                            )}
                          />
                        </Col> 
                        <Col xs={2} lg={2}>
                          <Controller name={`allocation.${index}.vehicle`} control={control} defaultValue={defaultVehicle}
                            render={({ field }) => (
                              <AsyncSelect
                                {...field} 
                                isClearable={true}
                                className='react-select w-100'
                                classNamePrefix='select'
                                placeholder='Search Vehicle'
                                isRtl={false}
                                name='callback-react-select'
                                loadOptions={loadVahicleOptions}
                                cacheOptions
                                onChange={(data: any) => {
                                  field.onChange(data)
                                  handleSelectedDriverAndVehicle('vehicle')
                                }}
                                isOptionDisabled = {(option) => {
                                  return preventVehicle?.some((elem) => elem?.value === option?.value)
                                }}
                                styles={styles}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      )) : (
                          <Row>
                              <Col xs={12} lg={12}>
                                  no Record found
                              </Col>
                          </Row>
                      )
                    }
                  </div>
                </div>
              </Row>
              <div className={`d-flex justify-content-between`}>
                <div>
                  { id && 
                    <>
                      <Button type="button" color="danger" onClick={() => setCenteredModal(true)} style={{ marginRight: "10px" }}>
                        Delete
                      </Button>
                      <Button type="button" color="primary" disabled={!notifyDriver?.length} onClick={() => setNotifyDriverModal(true)} >
                        Notify Driver
                      </Button>
                    </>
                  }
                </div>
                <div>
                  <Button type="button" className='btn btn-secondary' onClick={() => navigate(booking_id ? `/booking/${booking_id}/edit` : '/jobs')} style={{ marginRight: "10px" }}>
                    Close
                  </Button>
                  <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                    { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                  </Button>
                </div>
              </div>
            </Form>
          </UILoader>
        </Card>
      </Fragment>
    </>
  )
}

export {AddJobLeg}