import icons from '../icons-config/icons'
import {getLayoutFromLocalStorage} from '../../layout/core'

const IconComponent = ({className = '', iconType, iconName, style, onClick}) => {
  if (!iconType) {
    iconType = getLayoutFromLocalStorage().main?.iconType
  }

  return (
    <i className={`ki-${iconType} ki-${iconName}${className && ' ' + className}`} style={style} onClick={onClick}>
      {iconType === 'duotone' &&
        [...Array(icons[iconName])].map((_e, i) => {
          return (
            <span
              key={`${iconType}-${iconName}-${className}-path-${i + 1}`}
              className={`path${i + 1}`}
            ></span>
          )
        })}
    </i>
  )
}

export {IconComponent}
